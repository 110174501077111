/* eslint-disable */

import moment from "moment";
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import EkgRecording from "../chart/EkgRecording";
import {
  Box,
  Paper,
  Typography,
  Dialog,
  IconButton,
  Grid,
  SvgIcon,
  Divider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { calcRowSeconds } from "../chart/utils";
import * as Lib from "../chart/Constants/RecordingConstants";
import _get from "lodash.get";
import apiservice from "../../api/services/apiservice.service";
import { streamToPromise, getTimezoneName } from "../../Utilities/Utilities";
import { getAge } from "../../Utilities/Utilities";
import axios from "axios";
import { GoAPI } from "../../Axios/axios";
import * as API from './../../Services/API/actions'
import { getToken } from "../../Auth/AuthUtilities";
import EcgLoader from "../Shared/EcgLoader";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { getEKGAlgorithmDeterminationDisplayText } from '../../Utilities/recordingMaps';

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
    background: "#fff",
    border: "1px solid #ddd",
    padding: "5px",
    margin: "5px",
  },
  cardHeader: {
    fontWeight: "600",
  },
  darkcyanGrid: {
    fontWeight: "600",
    alignItems: "center",
    minHeight: "62.16px",
    padding: theme.spacing(1),
    background: "#142A39",
    color: "#FFFFFF",
  },
  blackGrid: {
    fontWeight: "600",
    alignItems: "center",
    minHeight: "58px",
    padding: theme.spacing(1),
    background: "#000000",
    color: "#FFFFFF",
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  gridPaddingLightgreen: {
    background: "#DFF0E9",
    minHeight: "62.16px",
    padding: theme.spacing(1),
  },
  gridPaddingLightyellow: {
    background: "rgba(253, 194, 70, 0.2)",
    borderRadius: "4px",
    minHeight: "48px",
    padding: theme.spacing(2),
  },
  mediumboldFont: {
    width: "64px",
    height: "12px",
    left: "17px",
    top: "151px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "12px",
    color: "#000000",
  },
  mediumboldFontfreesize: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#000000",
    whiteSpace: "pre-line",
    wordWrap: "break-word",
    wordBreak: "break-word",
  },
  boldFont: {
    fontWeight: 600,
    color: "#000000",
  },
  boldwhiteFont: {
    fontWeight: 600,
    color: "#FFFFFF",
  },
  greenFont: {
    fontWeight: 600,
    fontSize: "24px",
    // fontFamily: "SF Pro Text !important",
    color: "#2D9F86",
  },
  disclaimerFont: {
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#000000",
  },
  grayFont: {
    width: "24px",
    height: "13px",
    left: "18px",
    top: "137px",
    fontSize: "14px",
    lineHeight: "12px",
    color: "#536063",
  },
  grayFontfreesize: {
    // left: "18px",
    // top: "137px",
    fontSize: "12px",
    lineHeight: "12px",
    color: "#536063",
  },
  childvaluepadding: {
    marginTop: "5px",
  },
  bolderFont: {
    fontWeight: "700",
  },
  cardlayout: {
    minHeight: 600,
    background: "#F1F1F1",
  },
  cardlayoutmini: {
    minHeight: 280,
    // background: "#F1F1F1",
  },
  recordingInfo: {
    zIndex: 1,
    padding: "8px 10px",
    backgroundColor: "#fff",
    opacity: 0.8,
    width: "fit-content",
    display:
      "-webkit-box" && "-moz-box" && "-ms-flexbox" && "-webkit-flex" && "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    right: "10px",
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: "20px",
  },
  recordingInfospanfirstChild: {
    width: "30px",
    paddingLeft: "0px",
  },
  paperStyle: {
    padding: theme.spacing(1),
  },
  recordingDiv: {
    height: "600px",
    overflow: "auto",
  },
  recordingInfoWrapper: {
    position: "relative",
  },
  ekganalysisscroll: {
    minHeight: "100px",
    maxHeight: "300px",
    overflow: "auto",
  },
  marginpadded: {
    marginTop: "38px",
  },
  // marginleftclass:{
  //   marginLeft: "30px"
  // }
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ReportModal = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  let appointmentId = props.appointmentId;
    const teamId = useSelector((state) => state.user.team.id);

  const contentHeight = 500 - 124 - 14 + 34;
  const MIN_SECONDS = 1;
  const mainContentWidth = 914;
  const rowSeconds =
    calcRowSeconds(mainContentWidth) > 0
      ? calcRowSeconds(mainContentWidth)
      : MIN_SECONDS;
  const ekgWidth = rowSeconds * Lib.PX_PER_SECOND + 18;
  const [reportDetails, setReportDetails] = useState({});
  const [reportFooter,setreportFooter] = useState("");

  const loadAction = useCallback(async () => {
    let params = { timeZoneOffset: new Date().getTimezoneOffset() };
    let offsetInMinutes = new Date().getTimezoneOffset();
    const timezoneValue = getTimezoneName();
    setekgLoader(true);
    try {
      await streamToPromise(
        apiservice.getapiservice({
          baseUrl: `api/v1.1/teams/${teamId}/clinicians/${props.memberId}/appointments/${appointmentId}/overread-report?patientId=${props.patient.patientId}&timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
        })
      ).then((res) => {       
        setReportDetails(res.data.result);
      });
    } catch (e) {
      setekgLoader(false);
      setAlertOn(true);
      setreportDetailalertOn(true);
    }
  }, []);

  const decodeuridata = (data) => {
    try {
      return decodeURI(data);
    } catch (e) {
      return data;
    }
  };

  useEffect(() => {
    loadAction();
    API.reportFooter(teamId).subscribe(
      res=>{
        if(res.data["result"]!==null)
          setreportFooter(res.data["result"]);
      },
      error=>{
            console.log(error)
      }
    )
  }, []);
  const [recording, setRecording] = useState([]);
  const [ekgLoader, setekgLoader] = useState(false);
  const [alertOn, setAlertOn] = useState(false);
  const [reportDetailalertOn, setreportDetailalertOn] = useState(false);
  const [toggleRecordingInvert, setoggleRecordingInvert] = useState(false);

  const loadEkgAction = useCallback((recordingid) => {
    let samps = GoAPI.get(`/i/v1/recordings/${recordingid}/samples`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });
    let recs = GoAPI.get(`/i/v1/recordings/${recordingid}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    });

    axios
      .all([recs, samps])
      .then(
        axios.spread((...responses) => {
          setRecording({ ...responses[0].data, ...responses[1].data });
          setekgLoader(false);
        })
      )
      .catch((error) => {
        setekgLoader(false);
        setAlertOn(true);
        setRecording({});
      });
  }, []);

  useEffect(() => {
    if (
      reportDetails.patientDetail &&
      reportDetails.patientDetail.ekgResults &&
      reportDetails.patientDetail.ekgResults.length > 0
    ) {
      loadEkgAction(reportDetails.patientDetail.ekgResults[0].ekgRecordId);
    }
  }, [reportDetails.patientDetail]);
  
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"xl"}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
      >
        <DialogTitle
          id="customized-dialog-title-value"
          onClose={props.handleClose}
        >
          <Box px={2}>
            <Typography className={classes.bolderFont}>
              {t("Report Details")}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent className="bg_white1">
          {ekgLoader === true ? (
            <EcgLoader />
          ) : (
            <Grid
              container
              direction="row"
              justify="space-between"
              className="css_mui_flex_direction_row"
              style={{ display: "-ms-flexbox" }}
            >
              <Grid item xs className={classes.verticalgrid}>
                {reportDetailalertOn === true ? (
                  <Alert severity="warning">
                    {t("Report result not found!!!")}
                  </Alert>
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      className={classes.darkcyanGrid}
                    >
                      <Grid item xs={8} direction="column">
                        <Grid item>
                          {_get(reportDetails, "reportTitle") || "-"}
                        </Grid>
                        <Grid item>
                          {_get(reportDetails, "reportSubTitie") || "-"}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        direction="column"
                        className={classes.marginleftclass}
                      >
                        <Grid item>
                          <Typography
                            className={classes.boldwhiteFont + " mt-3"}
                          >
                            {t("Date")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.boldwhiteFont}>
                            {moment(
                              _get(
                                reportDetails,
                                "patientDetail.patientAppointment.scheduledStartTime"
                              ) || "-"
                            ).format("L")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={0}
                      className="css_mui_flex_direction_row"
                      style={{ display: "-ms-flexbox" }}
                    >
                      <Grid item xs={9}>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          className="css_mui_flex_direction_column"
                        >
                          <Typography className={classes.greenFont + " my-3"}>
                            {_get(
                              reportDetails,
                              "patientDetail.patient.lastName"
                            ) || "-"}
                            {","}{"   "}
                            {_get(
                              reportDetails,
                              "patientDetail.patient.firstName"
                            ) || "-"}
                          </Typography>
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="flex-start"
                              spacing={3}
                              className={classes.gridPadding}
                              style={{ display: "-ms-flexbox" }}
                            >
                              <Grid item>
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-between"
                                >
                                  <Grid item>
                                    <Typography
                                      className={
                                        classes.grayFontfreesize + " mb-2"
                                      }
                                    >
                                      {t("Sex")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.mediumboldFont}
                                    >
                                      {_get(
                                        reportDetails,
                                        "patientDetail.patient.gender"
                                      ) || "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-between"
                                >
                                  <Grid item>
                                    <Typography
                                      className={
                                        classes.grayFontfreesize + " mb-2"
                                      }
                                    >
                                      {t("Age")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.mediumboldFont}
                                    >
                                      {getAge(
                                        _get(
                                          reportDetails,
                                          "patientDetail.patient.dob"
                                        ) || "-"
                                      )}{" "}
                                      {t("Years")}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-between"
                                >
                                  <Grid item>
                                    <Typography
                                      className={classes.grayFont + " mb-2"}
                                    >
                                      {t("Wt")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.mediumboldFontfreesize}
                                    >
                                      {_get(
                                        reportDetails,
                                        "patientDetail.patient.weight"
                                      ) || "-"}{" "}
                                      lb
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-between"
                                >
                                  <Grid item>
                                    <Typography
                                      className={classes.grayFont + " mb-2"}
                                    >
                                      Ht
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.mediumboldFont}
                                    >
                                      {Math.floor(
                                        _get(
                                          reportDetails,
                                          "patientDetail.patient.height"
                                        ) / 30.48
                                      )}
                                      '{" "}
                                      {Math.floor(
                                        _get(
                                          reportDetails,
                                          "patientDetail.patient.height"
                                        ) / 2.54
                                      ) -
                                        12 *
                                          Math.floor(
                                            _get(
                                              reportDetails,
                                              "patientDetail.patient.height"
                                            ) / 30.48
                                          )}
                                      "
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid
                                  container
                                  direction="column"
                                  justify="space-between"
                                >
                                  <Grid item>
                                    <Typography
                                      className={
                                        classes.grayFontfreesize + " mb-2"
                                      }
                                    >
                                      {t("BMI")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      className={classes.mediumboldFont}
                                    >
                                      {_get(
                                        reportDetails,
                                        "patientDetail.patient.bmi"
                                      ) || "-"}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          className={classes.marginpadded}
                        >
                          <Grid item>
                            <Typography
                              className={classes.grayFontfreesize + " mt-3"}
                            >
                              {t("for EKG taken on")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={classes.boldFont}>
                              {_get(recording, "recordedAt") &&
                                moment(_get(recording, "recordedAt")).format(
                                  "LLL"
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Divider />
                    <br />

                    <Grid
                      container
                      direction="row"
                      spacing={0}
                      className="css_mui_flex_direction_row"
                      style={{ display: "-ms-flexbox" }}
                    >
                      <Grid item xs={9}>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          className="css_mui_flex_direction_column"
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="column"
                              justify="flex-start"
                              alignItems="flex-start"
                              // className={classes.gridPadding}
                              spacing={1}
                            >
                              <Grid item>
                                <Typography
                                  className={classes.grayFontfreesize + " mt-3"}
                                >
                                  {t("Reason for consultation")}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.mediumboldFontfreesize}
                                >
                                  {_get(
                                    reportDetails,
                                    "patientDetail.visitReason"
                                  ) || "No data"}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={classes.grayFontfreesize}
                                >
                                  {t("Symptoms")}
                                </Typography>
                              </Grid>
                              <Grid item className="min_height50">
                                {
                                  Object.keys(reportDetails).length > 0 &&
                                  reportDetails.patientDetail &&
                                  reportDetails.patientDetail.symptomString &&
                                  reportDetails.patientDetail.symptomString
                                    .length > 0 ? (
                                    <Typography
                                      className={classes.mediumboldFontfreesize}
                                    >
                                      {
                                        reportDetails.patientDetail
                                          .symptomString
                                      }
                                    </Typography>
                                  ) : (
                                    <Typography
                                      className={classes.mediumboldFontfreesize}
                                    >
                                      No data
                                    </Typography>
                                  )

                                  // reportDetails.patientDetail.symptoms.map(
                                  //   (item, index) => (
                                  //     <div key={index}>
                                  //       <div>
                                  //         <Typography
                                  //           className={
                                  //             classes.mediumboldFontfreesize
                                  //           }
                                  //         >
                                  //           {item.name}
                                  //         </Typography>
                                  //         {/* , */}
                                  //       </div>
                                  //     </div>
                                  //   )
                                  // )
                                }
                              </Grid>
                              <Grid item>
                                <br />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                        >
                          <Grid item>
                            <Typography
                              className={classes.grayFontfreesize + " mt-3"}
                            >
                              {t("Method of communication")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={classes.boldFont}>
                              {_get(
                                reportDetails,
                                "patientDetail.patientAppointment.appointmentChannelType"
                              ) || "-"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.grayFontfreesize + " mt-3"}
                            >
                              {t("Cardiologist")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className={classes.boldFont}>
                              Dr.{" "}
                              {_get(
                                reportDetails,
                                "physicianModel.firstName"
                              ) || "-"}{" "}
                              {_get(reportDetails, "physicianModel.lastName") ||
                                "-"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* here starts consultancy summary */}
                    <Divider />
                    <br />
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      className="css_mui_flex_direction_row"
                      style={{ display: "-ms-flexbox" }}
                    >
                      <Grid item xs={3}>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          className="css_mui_flex_direction_column"
                        >
                          <Grid item>
                            <Typography className={classes.grayFontfreesize}>
                              {t("Medical history")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.childvaluepadding}>
                            <Typography
                              className={classes.mediumboldFontfreesize}
                            >
                              {_get(
                                reportDetails,
                                "patientDetail.medicalHistory"
                              ) || "No data"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.grayFontfreesize}>
                              {t("Medications")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.childvaluepadding}>
                            <Typography
                              className={classes.mediumboldFontfreesize}
                            >
                              {_get(
                                reportDetails,
                                "patientDetail.medications"
                              ) || "No data"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.grayFontfreesize}>
                              {t("Allergies")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.childvaluepadding}>
                            <Typography
                              className={classes.mediumboldFontfreesize}
                            >
                              {_get(
                                reportDetails,
                                "patientDetail.allergyDetails"
                              ) || "No data"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.grayFontfreesize}>
                              {t("Surgeries")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.childvaluepadding}>
                            <Typography
                              className={classes.mediumboldFontfreesize}
                            >
                              {_get(
                                reportDetails,
                                "patientDetail.surgeryDetails"
                              ) || "No data"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.grayFontfreesize}>
                              {t("Family history")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.childvaluepadding}>
                            <Typography
                              className={classes.mediumboldFontfreesize}
                            >
                              {_get(
                                reportDetails,
                                "patientDetail.familyMedicalHistory"
                              ) || "No data"}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <Typography className={classes.grayFontfreesize}>
                              {t("Social history")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.childvaluepadding}>
                            {
                              Object.keys(reportDetails).length > 0 &&
                              reportDetails.patientDetail &&
                              reportDetails.patientDetail
                                .lifeStyleHistoryStr ? (
                                <Typography
                                  className={classes.mediumboldFontfreesize}
                                >
                                  {
                                    reportDetails.patientDetail
                                      .lifeStyleHistoryStr
                                  }
                                </Typography>
                              ) : (
                                <Typography
                                  className={classes.mediumboldFontfreesize}
                                >
                                  No data
                                </Typography>
                              )
                              // reportDetails.patientDetail.lifeStyleHistory.map(
                              //   (item, index) => (
                              //     <div>
                              //       {" "}
                              //       <Typography
                              //         className={classes.mediumboldFontfreesize}
                              //       >
                              //         {item.name}{" "}
                              //       </Typography>
                              //       {/* , */}
                              //     </div>
                              //   )
                              // )
                            }
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={9}>
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          className="css_mui_flex_direction_column"
                        >
                          <Grid item>
                            <div className="telekardia">
                              <div className="hr_1" />
                            </div>
                            <Typography
                              className={classes.bolderFont + " mb-2"}
                            >
                              {t("EKG review")}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.ekganalysisscroll}>
                            <Paper elevation={3} square className="mt-2">
                              <Box
                                pl={1}
                                border={8}
                                borderTop={0}
                                borderRight={0}
                                borderBottom={0}
                                borderColor={
                                  _get(
                                    reportDetails,
                                    "patientEncounterSummary.encounterDetails.heartRateConditionColorCode"
                                  ) || "#FFFFFF"
                                }
                              >
                                <Typography
                                  className={classes.bolderFont + " px-2"}
                                >
                                  {_get(
                                    reportDetails,
                                    "patientEncounterSummary.encounterDetails.heartRateCondition"
                                  ) || "-"}
                                </Typography>
                                <Typography
                                  className={
                                    classes.mediumboldFontfreesize + " px-2"
                                  }
                                >
                                  {decodeuridata(
                                    _get(
                                      reportDetails,
                                      "patientEncounterSummary.encounterDetails.overReadNotes"
                                    ) || "-")}
                                </Typography>
                              </Box>
                            </Paper>
                            <Grid item>
                              <br />
                            </Grid>
                          </Grid>

                          <Grid item>
                            <div className="telekardia">
                              <div className="hr_2" />
                            </div>
                            <Typography
                              className={classes.bolderFont + " mb-2"}
                            >
                              {t("Consultation summary")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={classes.mediumboldFontfreesize}
                            >
                              {decodeuridata(
                                _get(
                                  reportDetails,
                                  "patientEncounterSummary.encounterDetails.consultationSummaryNotes"
                                ) || "-"
                              )}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <br />
                          </Grid>
                          <Grid item>
                            <div className="telekardia">
                              <div className="hr_3" />
                            </div>
                            <Typography
                              className={classes.bolderFont + " mb-2"}
                            >
                              {t("Recommended action")}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography
                              className={
                                classes.mediumboldFontfreesize + " mb-2"
                              }
                            >
                              {decodeuridata(
                                _get(
                                  reportDetails,
                                  "patientEncounterSummary.encounterDetails.treatmentPlan"
                                ) || "-"
                              )}
                            </Typography>
                          </Grid>
                          {_get(
                            reportDetails,
                            "patientEncounterSummary.encounterDetails.isFollowUp"
                          ) === true && (
                            <Grid
                              item
                              className={classes.gridPaddingLightyellow}
                            >
                              <Grid
                                container
                                // direction="row"
                                alignItems="center"
                                justify="flex-start"
                                spacing={2}
                              >
                                <Grid item>
                                  <SvgIcon viewBox="0 0 20 20">
                                    <path
                                      fill="#142A39"
                                      d="M9 7H11V5H9V7ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0ZM9 15H11V9H9V15Z"
                                    />
                                  </SvgIcon>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    className={classes.mediumboldFontfreesize}
                                  >
                                    {t(
                                      "Cardiologist requested a follow-up consultation in"
                                    )}{" "}
                                    {_get(
                                      reportDetails,
                                      "patientEncounterSummary.encounterDetails.followUpDuration"
                                    ).toLowerCase() || "-"}
                                    .
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <br />
                    <Typography className={classes.disclaimerFont}>
                      {reportFooter}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs className={classes.verticalgrid}>
                {alertOn === true ? (
                  <Alert severity="warning">EKG result not found!!!</Alert>
                ) : (
                  <Box px={0}>
                    <Grid container className={classes.gridPaddingLightgreen}>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        spacing={3}
                        className="p-2"
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justify="space-between"
                          >
                            <Grid item>
                              <Typography
                                className={classes.grayFontfreesize + " mb-2"}
                              >
                                {t("EKG Taken")}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.mediumboldFontfreesize}
                              >
                                {moment(
                                  _get(recording, "recordedAt") || "-"
                                ).format("LLL")}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justify="space-between"
                          >
                            <Grid item>
                              <Typography
                                className={classes.grayFontfreesize + " mb-2"}
                              >
                                {t("EKG Analysis")}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.mediumboldFontfreesize}
                              >
                                {getEKGAlgorithmDeterminationDisplayText(
                                  _get(recording, "algorithmDetermination") ||
                                    "-",
                                  _get(recording, "algorithmPackage") || "-"
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justify="space-between"
                          >
                            <Grid item>
                              <Typography
                                className={classes.grayFontfreesize + " mb-2"}
                              >
                                {t("Tags")}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                className={classes.mediumboldFontfreesize}
                              >
                                {Object.keys(recording).length > 0 &&
                                  recording.tags &&
                                  recording.tags.length > 0 &&
                                  recording.tags.map((item, index) => (
                                    <div key={index}>
                                      <div>{item.name},</div>
                                    </div>
                                  ))}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <div className={classes.recordingDiv + " mt-2"}>
                      <div className={classes.recordingInfoWrapper}>
                        <div className={classes.recordingInfo}>
                          <div className={classes.recordingInfospan}>
                            25 mm/s
                          </div>
                          <div className={classes.recordingInfospan}>
                            10 mm/mV
                          </div>
                          {!recording.is6l && (
                            <div className={classes.recordingInfospan}>
                              {" "}
                              <a
                                onClick={() =>
                                  setoggleRecordingInvert(
                                    !toggleRecordingInvert
                                  )
                                }
                              >
                                {" "}
                                {t("Invert ECG")}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      <EkgRecording
                        height={621}
                        width={ekgWidth}
                        recording={recording}
                        calpulse={false}
                        rowSeconds={rowSeconds}
                        inverted={toggleRecordingInvert}
                        is6l={recording.is6l}
                      ></EkgRecording>
                    </div>

                    <br />
                    <Typography className={classes.disclaimerFont}>
                    {reportFooter}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ReportModal;
