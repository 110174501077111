import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";



const useStyles = makeStyles({
  typographyprops: {
    fontFamily: props => props.fontFamily,
    fontStyle: props => props.fontStyle,
    fontWeight: props => props.fontWeight,
    fontSize: props =>props.fontSize,
    lineHeight: props => props.lineHeight,
    color: props => props.color,
    textTransform: props => props.textTransform,
    margin:props=>props.margin,
    textAlign:props=>props.textAlign,
    alignItems: props=>props.alignItems,
    padding:props=>props.padding
  },
});

const MuiTypography = (props) => {
  const { children } = props;
  const classes = useStyles(props);
 
  return   <Typography className={classes.typographyprops}>{children}</Typography>
};

export default MuiTypography;
