/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom';
import Errorpage from './Components/Errorpage';
import * as actions from './Store/actions'
import { setResetIdleTime, timeRemainingOnTokenMs } from './Auth/AuthUtilities'
import Feedbacks from './Components/Feedbacks/Feedbacks';
import AdminDashBoard from './Components/AdminDashBoard/AdminDashBoard';
import { connect } from 'react-redux'
import AdminCalendar from './Components/AdminCalendar/AdminCalendar';
import AdminConsultations from './Components/AdminConsultations/AdminConsultations';
import { useTranslation } from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import {
  Grid,Container,Snackbar
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import AccessDeniedContent from "./Components/Shared/AccessDeniedContent/AccessDeniedContent";
import OfflinePopup from './Components/Appointments/Calendar/OfflinePopup';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
  accessDeniedContent:{
      backgroundColor:"#FFFFFF",
      margin:"50px 0 0 0 ",
      padding:"40px"
    }
}))
const useNetwork = onChange => {
  const [status , setStatus] = useState(navigator.onLine);
  const handleChange = () => {
      if(typeof onChange === 'function') {
          onChange(navigator.onLine);
      }
      setStatus(navigator.onLine);
  };
  useEffect( () => {
      window.addEventListener('online' , handleChange);  
      window.addEventListener('offline' , handleChange); 
      return () => {
          window.removeEventListener('online' , handleChange);  
          window.removeEventListener('offline' , handleChange);  
      } ;
  }, []);
  return status;
}

const AppAdmin = (props) => {
  const [offlinealertopen, setofflinealertopen] = React.useState(false);
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [networkstatus, setnetworkstatus] = useState("online");
  const handleNetworkChange = (online) => {
    online ? setofflinealertopen(false) : setofflinealertopen(true);
    online ? setnetworkstatus("online") : setnetworkstatus("We just offline");
  };

  const closeofflinepopup = () => {
    setofflinealertopen(false);
  };

const onLine = useNetwork(handleNetworkChange);

const handlesnackbarClose = (event, reason) => {    

  setsnackbaropen(false);
};


  const classes = useStyles();
  const { t, i18n } = useTranslation();
  let content = 
    (
      props.userstate.data.profile.permissions.isAdmin?

    <div onClick={setResetIdleTime}>
      <div>
        {/* <Route exact path="/admin" component={Feedbacks} /> */}
        <Route path="/admin/feedbacks" component={Feedbacks} />
        <Route path="/admin/dashboard" component={AdminDashBoard} />
        <Route path="/admin/calendar" component={AdminCalendar }/>
        <Route path="/admin/consultations" component={AdminConsultations} />
      </div>
      {
        <OfflinePopup
          open={offlinealertopen}
          closeofflinepopup={closeofflinepopup}
        />
      }
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={snackbaropen} autoHideDuration={3000} onClose={handlesnackbarClose}>
      <Alert onClose={handlesnackbarClose} severity={networkstatus === "online" ? "success" :"error"}>
      {networkstatus}
      </Alert>
   </Snackbar>
    </div>
    :
    <Container maxWidth="xl">
                    <Grid container alignItems="center" justify="flex-start" className={classes.accessDeniedContent}>
                        <AccessDeniedContent/>
                    </Grid>
                    </Container>
  );

  function getSupportedLocales() {
    if (!process.env.REACT_APP_I18N_SUPPORTED_LOCALES) {
      return ['en'];
    }
    return process.env.REACT_APP_I18N_SUPPORTED_LOCALES.split(',');
  }

  function isSupportedLocale(locale) {
    return getSupportedLocales().includes(locale);
  }
  
  
  
  function getLocale() {
    const browserLocale = navigator.language;
    if (browserLocale && isSupportedLocale(browserLocale)) {
      return browserLocale;
    }
  
    // fallback to base locale if we have it
    if (browserLocale && browserLocale.indexOf('-') !== -1) {
      // en-US -> 'en' or 'de-DE' -> 'de'
      const baseLocale = browserLocale.split('-')[0];
      if (isSupportedLocale(baseLocale)) {
        console.log(`Using base locale: ${baseLocale}`);
        return baseLocale;
      }
    }
  }

  useEffect(() => {
    // let language = localStorage.getItem("kardiapro_region") || 'en';   
    
    let locale = getLocale();
    i18n.changeLanguage(locale);
    if ((!props.gstate.user.profile) || (props.gstate.user.profile.id !== props.userstate.data.profile.id)) {
      if (props.userstate) {
        props.onAddUser(props.userstate.data);
      }
      else {
        return <Errorpage />;
      }
    }
    return()=>{
      props.onAddUser("logOut");
    }
  }, [props.userstate.data])

  if (!(timeRemainingOnTokenMs() > 0)) {
    //redirect to home
   // props.onUserLogout();
    console.log("Token Expired or Invalid - Redirecting to Login");
    window.location.replace("http://" + window.location.host + "/login");
  }
  else if (props.gstate.user.profile) {
    return (
      <div>
        <BrowserRouter>{content}</BrowserRouter>
      </div>
    );
  }
  else
    return <div></div>
}


const mapStateToProps = state => {
  return {
    gstate: state
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onAddUser: (x) => dispatch(actions.addUser(x))
//    onUserLogout: () => dispatch(actions.user_logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAdmin);
